<template>
  <Header />
  <template v-if="isPC">
    <div id="logoPC" class="logo-wrapper">
      <VueSlickCarousel
          ref="slick"
          infinite
          dots
          slides-to-show="1"
          :arrows="false"
          :draggable="false"
          css-ease="ease-in"
          fade
          autoplay
          autoplay-speed="7000"
          speed="1200"
          :swipe="false"
          :pause-on-hover="false"
          :pause-on-dots-hover="false"
      >
        <img src="@/assets/images/top/pc_img_top_01.png" alt="ひなこい 日向坂46">
        <img src="@/assets/images/top/pc_img_top_02.png">
      </VueSlickCarousel>
    </div>
  </template>
  <template v-else>
    <div id="logoSP" class="logo-wrapper">
      <VueSlickCarousel
          ref="slick"
          infinite
          dots
          slides-to-show="1"
          :arrows="false"
          :draggable="false"
          css-ease="ease-in"
          fade
          autoplay
          autoplay-speed="7000"
          speed="1200"
          :swipe="false"
          :pause-on-hover="false"
          :pause-on-dots-hover="false"
      >
        <img src="@/assets/images/top/sp_img_top_01.png" alt="日向坂46と思いっきり恋をしよう！日向坂46公式ゲームアプリ">
        <img src="@/assets/images/top/sp_img_top_02.png">
      </VueSlickCarousel>
    </div>
  </template>
  <div class="top-store-button">
    <a href="https://app.adjust.com/11kxtk16?redirect=https%3A%2F%2Fapps.apple.com%2Fjp%2Fapp%2F%25E6%2597%25A5%25E5%2590%2591%25E5%259D%258246%25E5%2585%25AC%25E5%25BC%258F-%25E3%2581%25B2%25E3%2581%25AA%25E3%2581%2593%25E3%2581%2584%2Fid1489909693" target="_blank">
      <img src="@/assets/images/campaign/pc_btn_apple_dl.png" alt="" class="apple-btn-img js-image-switch">
    </a>
    <a href="https://app.adjust.com/11c0cmdc?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Djp.co.tenantz.hinakoi" target="_blank">
      <img src="@/assets/images/campaign/pc_btn_google_dl.png" alt="" class="google-btn-img js-image-switch">
    </a>
  </div>
  <div class="app-pay-banner">
    <a href="https://t.co/NgJZYZCSeD" target="_blank">
      <img src="@/assets/images/top/AppPay_header_202406.png" alt="">
    </a>
  </div>
  <div class="app-pay-banner">
    <a href="https://gaga.ne.jp/zenbu_of_tokyo/" target="_blank">
      <img src="@/assets/images/top/zenbu.png" alt="">
    </a>
  </div>
  <div class="movie-wrap">
    <div class="movie-block">
      <iframe class="movie-block-iframe" src="https://www.youtube.com/embed/NVcjc6Y6asE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import resolution from '@/mixin/resolution';
import { VueSlickCarousel } from 'vue-slick-ts'
import "vue-slick-ts/dist/css/slick.css";
import "vue-slick-ts/dist/css/slick-theme.css";
export default {
  name: 'TopPage',
  components: {
    Header,
    Footer,
    VueSlickCarousel
  },
  mixins: [resolution],
  data() {
    return {
      slickOptions: {
        infinite: true,         //無限ループ
        dots: true,            //ドットナビゲーション非表示
        slidesToShow: 1,        //スライド数：１
        slidesToScroll: 1,
        arrows: false,          //矢印ナビ非表示
        fade: true,              //fade適用
        cssEase: 'ease-in',     //CSSアニメーション適用
        draggable: false,        //ドラッグを無効
        swipe: false,           //スワイプを無効
        speed: 1200,            //スライドが切り替わる速さ

        //自動再生設定
        autoplay: true,         //自動再生をONにする
        autoplaySpeed: 7000,    //自動再生のスピード

        //ユーザが触れた時に再生を止めるか
        pauseOnHover: false,
        pauseOnDotsHover: false
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.logo-wrapper {
  img {
    width: 100%;
  }
}
::v-deep(.slick-dots) {
  bottom: -33px;
  li {
    opacity: 1;
    border-radius: 10px;
    background: #e0e7f8;
    width: 9px;
    height: 9px;
    margin: 11px;
    &.slick-active {
      background: #88cbff;
    }
  }
}
.top-store-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px auto 40px;
  a {
    display: block;
    height: 65px;
    margin: 0 10px;
    img {
      width: auto;
      height: 65px;
    }
  }
}
.app-pay-banner {
  width: 50%;
  margin: 0 auto 40px;
  a {
    display: block;
    img {
      width: 100%;
    }
  }
}
.movie-wrap {
  text-align: center;
  .movie-block-text {
    position: relative;
    width: 560px;
    margin: 10px auto;
    left: -16px;
    text-align: left;
    color: #5ba7f2;
  }
  .movie-block-iframe {
    border: 1px solid #5bdac5;
    padding: 7px;
    width : 560px;
    height : 315px;
  }
}
@media screen and (max-width: 1023px) {
  ::v-deep(.slick-dots) {
    bottom: -15px;
    li {
      opacity: 1;
      border-radius: 10px;
      background: #e0e7f8;
      width: 6px;
      height: 6px;
      margin: 6px;
      &.slick-active {
        background: #88cbff;
      }
    }
  }
  .top-store-button {
    margin: 30px auto 20px;
    a {
      height: 36px;
      margin: 0 8px;
      img {
        height: 36px;
      }
    }
  }
  .app-pay-banner {
    width: 80%;
    margin: 0 auto 20px;
    a {
      display: block;
      img {
        width: 100%;
      }
    }
  }
  .movie-wrap {
    .movie-block-text {
      width: 300px;
      left: -18px;
    }
    .movie-block-iframe {
      width : 300px;
      height : 180px;
    }
  }
}
</style>
